import React, { useState } from "react";

import Logo from "../images/consult-logo-white-green.png";
import Jason from "../images/Team/jason_edited.jpg";
import Commercial from "../images/comercial-screenshot.png";
import HeroBG from "../images/ddd2.png";
import FormBG from "../images/Form-BG.png";
import QuoteBG from "../images/Quote-BG.png";
import { Helmet } from "react-helmet";
import Burger from "../components/Burger/Burger";
import Footer from "../components/Footer";

const BetterMarketing = () => {
  const [FormState, setFormState] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    websiteLink: "",
    referral: "none",
    message: "",
  });

  const [SubmitDisabled, setSubmitDisabled] = useState(false);

  const handleChange = (e) => {
    setFormState({
      ...FormState,
      [e.target.name]: e.target.value,
    });
  };

  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  };

  const handleSubmit = (e) => {
    setSubmitDisabled(true);
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "Marketing Contact", ...FormState }),
    })
      .then(() => window.open("../thank-you", "_self"))
      .catch(() => window.open("../Error", "_self"));

    e.preventDefault();
  };
  console.log(FormState);
  return (
    <div>
      <Helmet>
        <title>
          Better Digital Marketing Company | Consult FGC | Dunedin, FL
        </title>
        <meta
          name="description"
          content="Take your digital marketing to the next level with the people who know it best. Dive into the digital world with geofencing, programmatic advertising, & more!"
        />
        <link
          rel="icon"
          href="https://consultfgc.netlify.app/static/ConsultFGC_Logo_footer-8b0c6cdcc10d6cc56b61a7b78ed851b7.png"
        />
      </Helmet>

      <div class="relative isolate">
        <div class="inset-0 ">
          <img
            src={HeroBG}
            class="w-full h-full object-cover absolute mix-blend-overlay"
            alt=""
          />

          <div class="z-50 relative max-w-7xl mx-auto px-6 sm:px-6">
            <div class="relative w-full py-4 flex items-center justify-between  lg:border-none">
              <div class="flex items-center"></div>
              <a href="/">
                <img
                  class="pt-5 md:pt-10 mx-auto mr-10 md:mr-0 w-8/12 md:w-full md:max-w-md"
                  src={Logo}
                />
              </a>
              <button
                type="button"
                class="ml-5 inline-flex items-center justify-center rounded-md text-white hover:text-gray-200"
                aria-controls="mobile-menu"
                aria-expanded="false"
              >
                <Burger />
              </button>
            </div>{" "}
          </div>
        </div>
        <main class="section one">
          <div class="relative mx-auto max-w-7xl pt-10">
            <div class="mx-auto max-w-7xl px-6 lg:px-8">
              <div class="items-center text-center md:justify-center ">
                <div class="mx-auto">
                  <h1 class="pt-5 font-semibold text-5xl text-white md:text-8xl">
                    Marketing Done <span class="underline">Right</span>
                  </h1>
                </div>
              </div>

              <div class="hidden md:block mx-auto max-w-4xl mt-14 mb-16 flow-root md:mb-0">
                <div className="video"></div>
                <div class="max-w-4xl flex justify-center">
                  <div class="rounded-xl bg-gray-200 p-2 ring-1 ring-inset ring-gray-900/10 mb-16 lg:rounded-2xl lg:p-4">
                    <iframe
                      width="960"
                      height="540"
                      src="https://www.youtube.com/embed/pwyJvtLsAlU"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                      frameBorder="0"
                      webkitallowfullscreen="true"
                      mozallowfullscreen="true"
                      class="rounded-md shadow-2xl ring-1 ring-gray-900/10"
                    ></iframe>
                  </div>
                </div>
              </div>
              <div class="md:hidden mx-auto max-w-4xl mt-14 flow-root md:mb-0">
                <div className="video"></div>
                <div class="max-w-4xl flex justify-center">
                  <div class="rounded-xl bg-gray-200 p-2 ring-1 ring-inset ring-gray-900/10 mb-16 lg:rounded-2xl lg:p-4">
                    <iframe
                      width="427"
                      height="240"
                      src="https://www.youtube.com/embed/pwyJvtLsAlU"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                      frameBorder="0"
                      webkitallowfullscreen="true"
                      mozallowfullscreen="true"
                      class="rounded-md shadow-2xl ring-1 ring-gray-900/10"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="section three relative ">
            <div class="absolute inset-0 ">
              <img
                class="h-full w-full"
                src={FormBG}
                alt="Gradient Background"
              />
            </div>
            <div class="relative mx-auto max-w-7xl px-6 lg:px-8 pt-14 pb-14">
              <div class="mx-auto grid max-w-2xl grid-cols-1 gap-y-16 gap-x-8 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                <div class="lg:pr-8 lg:pt-4">
                  <div class="">
                    <h2 class="hidden md:block md:space-x-6 md:text-gray-700 md:font-semibold md:text-7xl ">
                      Move Into the Digital World.
                    </h2>

                    <h2 class="mobile tracking-tight text-gray-700 text-4xl font-extrabold md:hidden ">
                      Move Into the Digital World.
                    </h2>

                    <p class="pt-10 text-xl md:text-2xl text-indigo-700">
                      Let us help you get in front of the
                      {""} <span class="italic">right</span> {""}
                      audience at the
                      {""} <span class="italic">right</span> {""}
                      time on the
                      {""} <span class="italic">right</span> {""}
                      platform.
                    </p>
                    <h2 class="mt-5 text-xl text-indigo-700 font-semibold">
                      We specialize in:
                    </h2>
                    <dl class="mt-8 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                      <div class="relative pl-14">
                        <dt class="items-center inline font-semibold text-gray-900">
                          <svg
                            class="absolute left-0  h-8 w-8 text-indigo-700"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                            aria-hidden="true"
                          >
                            <path d="M22 8.51v1.372h-2.538c.02-.223.038-.448.038-.681 0-.237-.017-.464-.035-.69h2.535zm-10.648-6.553v-1.957h1.371v1.964c-.242-.022-.484-.035-.726-.035-.215 0-.43.01-.645.028zm5.521 1.544l1.57-1.743 1.019.918-1.603 1.777c-.25-.297-.593-.672-.986-.952zm-10.738.952l-1.603-1.777 1.019-.918 1.57 1.743c-.392.28-.736.655-.986.952zm-1.597 5.429h-2.538v-1.372h2.535c-.018.226-.035.454-.035.691 0 .233.018.458.038.681zm9.462 9.118h-4c-.276 0-.5.224-.5.5s.224.5.5.5h4c.276 0 .5-.224.5-.5s-.224-.5-.5-.5zm0 2h-4c-.276 0-.5.224-.5.5s.224.5.5.5h4c.276 0 .5-.224.5-.5s-.224-.5-.5-.5zm.25 2h-4.5l1.188.782c.154.138.38.218.615.218h.895c.234 0 .461-.08.615-.218l1.187-.782zm3.75-13.799c0 3.569-3.214 5.983-3.214 8.799h-1.989c-.003-1.858.87-3.389 1.721-4.867.761-1.325 1.482-2.577 1.482-3.932 0-2.592-2.075-3.772-4.003-3.772-1.925 0-3.997 1.18-3.997 3.772 0 1.355.721 2.607 1.482 3.932.851 1.478 1.725 3.009 1.72 4.867h-1.988c0-2.816-3.214-5.23-3.214-8.799 0-3.723 2.998-5.772 5.997-5.772 3.001 0 6.003 2.051 6.003 5.772z" />
                          </svg>
                          <p class="text-lg text-gray-600">
                            {" "}
                            Google & Search Engine Marketing
                          </p>
                        </dt>
                      </div>
                      <div class="relative pl-14">
                        <dt class="items-center inline font-semibold text-gray-900">
                          <svg
                            class="absolute top-1 left-0  h-8 w-8 text-indigo-700"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                            aria-hidden="true"
                          >
                            <path d="M18 0c-3.148 0-6 2.553-6 5.702 0 4.682 4.783 5.177 6 12.298 1.217-7.121 6-7.616 6-12.298 0-3.149-2.852-5.702-6-5.702zm0 8c-1.105 0-2-.895-2-2s.895-2 2-2 2 .895 2 2-.895 2-2 2zm-12-3c-2.099 0-4 1.702-4 3.801 0 3.121 3.188 3.451 4 8.199.812-4.748 4-5.078 4-8.199 0-2.099-1.901-3.801-4-3.801zm0 5.333c-.737 0-1.333-.597-1.333-1.333s.596-1.333 1.333-1.333 1.333.596 1.333 1.333-.596 1.333-1.333 1.333zm6 5.775l-3.215-1.078c.365-.634.777-1.128 1.246-1.687l1.969.657 1.92-.64c.388.521.754 1.093 1.081 1.75l-3.001.998zm12 7.892l-6.707-2.427-5.293 2.427-5.581-2.427-6.419 2.427 3.62-8.144c.299.76.554 1.776.596 3.583l-.443.996 2.699-1.021 4.809 2.091.751-3.725.718 3.675 4.454-2.042 3.099 1.121-.461-1.055c.026-.392.068-.78.131-1.144.144-.84.345-1.564.585-2.212l3.442 7.877z" />
                          </svg>
                          <p class="pt-2 text-lg text-gray-600">
                            {" "}
                            Geofence Advertising & Hyperlocal Marketing
                          </p>
                        </dt>
                      </div>
                      <div class="relative pl-14">
                        <dt class="items-center inline font-semibold text-gray-900">
                          <svg
                            class="absolute top-1 left-0  h-8 w-8 text-indigo-700"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                            aria-hidden="true"
                          >
                            <path d="M24 23h-20c-2.208 0-4-1.792-4-4v-15.694c.313-1.071 1.285-2.306 3-2.306 1.855 0 2.769 1.342 2.995 2.312l.005 1.688h18v18zm-2-16h-16v11s-.587-1-1.922-1c-1.104 0-2.078.896-2.078 2s.896 2 2 2h18v-14zm-2 12h-12v-10h12v10zm-8-9h-3v8h10v-8h-6v3h6v1h-2v3h-1v-3h-3v3h-1v-7z" />
                          </svg>
                          <p class="pt-2 text-lg text-gray-600">
                            {" "}
                            Custom Web Design & Development
                          </p>
                        </dt>
                      </div>
                      <div class="relative pl-14">
                        <dt class="items-center inline font-semibold text-gray-900">
                          <svg
                            class="absolute top-1 left-0  h-8 w-8 text-indigo-700"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                            aria-hidden="true"
                          >
                            <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
                          </svg>
                          <p class="pt-2 text-lg text-gray-600">
                            {" "}
                            Social Media Marketing
                          </p>
                        </dt>
                      </div>
                      <div class="relative pl-14">
                        <dt class="items-center inline font-semibold text-gray-900">
                          <svg
                            class="absolute top-1 left-0 h-8 w-8 text-indigo-700"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                            aria-hidden="true"
                          >
                            <path d="M20.699 11.069c1.947 1.015 3.3 2.81 3.3 4.977 0 1.204-.433 2.387-1.202 3.304-.032 1.075.601 2.618 1.171 3.741-1.529-.277-3.702-.886-4.686-1.49-.833.202-1.631.295-2.385.295-2.919 0-5.185-1.398-6.278-3.271 6.427-.207 9.818-4.482 10.08-7.556zm-10.798-10.07c-5.282 0-10 3.522-10 8.34 0 1.708.615 3.385 1.705 4.687.046 1.525-.851 3.713-1.66 5.307 2.168-.392 5.251-1.257 6.648-2.114 7.696 1.873 13.307-2.837 13.307-7.88 0-4.844-4.751-8.34-10-8.34zm3.048 10.74c-.212.071-.441-.044-.511-.256 0 0-1.496-.501-3.272.088l1.166 1.814c.121.179.037.422-.164.49l-.794.263-.167.028c-.149 0-.294-.064-.396-.179l-1.604-1.77c-.873.276-1.862-.042-2.192-.865-.076-.188-.114-.389-.114-.592 0-.585.327-1.183 1.038-1.533 3.559-1.747 4.128-3.696 4.128-3.696-.086-.262.11-.532.382-.532.169 0 .327.108.383.277l2.371 5.952c.071.212-.044.441-.254.511zm1.687-2.043l-.614-.261c.267-.634.293-1.371.014-2.058-.279-.688-.808-1.199-1.441-1.466l.26-.615c1.265.535 2.046 1.771 2.046 3.091 0 .454-.092.898-.265 1.309zm-1.063-.451l-.599-.254c.154-.365.169-.789.008-1.185-.16-.396-.466-.69-.83-.843l.253-.601c.518.219.952.635 1.179 1.198.229.564.207 1.165-.011 1.685z" />
                          </svg>
                          <p class="pt-2 text-lg text-gray-600">
                            {" "}
                            Programmatic Advertising
                          </p>
                        </dt>
                      </div>
                      <div class="relative pl-14">
                        <dt class="items-center inline font-semibold text-gray-900 ">
                          <svg
                            class="absolute top-1 left-0 h-8 w-8 text-indigo-700"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                            aria-hidden="true"
                          >
                            <path d="m21 4c0-.478-.379-1-1-1h-16c-.62 0-1 .519-1 1v16c0 .621.52 1 1 1h16c.478 0 1-.379 1-1zm-3 11.25c0 .414-.336.75-.75.75h-4.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h4.5c.414 0 .75.336.75.75zm-11.772-.537c-.151-.135-.228-.321-.228-.509 0-.375.304-.682.683-.682.162 0 .324.057.455.173l.746.665 1.66-1.815c.136-.147.319-.221.504-.221.381 0 .684.307.684.682 0 .163-.059.328-.179.459l-2.116 2.313c-.134.147-.319.222-.504.222-.162 0-.325-.057-.455-.173zm11.772-2.711c0 .414-.336.75-.75.75h-4.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h4.5c.414 0 .75.336.75.75zm-11.772-1.613v.001c-.151-.135-.228-.322-.228-.509 0-.376.304-.682.683-.682.162 0 .324.057.455.173l.746.664 1.66-1.815c.136-.147.319-.221.504-.221.381 0 .684.308.684.682 0 .164-.059.329-.179.46l-2.116 2.313c-.134.147-.319.221-.504.221-.162 0-.325-.057-.455-.173zm11.772-1.639c0 .414-.336.75-.75.75h-4.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h4.5c.414 0 .75.336.75.75z" />
                          </svg>
                          <p class="pt-2 text-lg text-gray-600"> + More!</p>
                        </dt>
                      </div>
                    </dl>
                  </div>
                </div>
                <div class="bg-gradient-to-r from-violet-900 to-[#00538a] rounded-xl py-10 px-6 sm:px-10">
                  <h3 class="text-2xl mb-10 font-medium text-white">
                    Send us a message
                  </h3>
                  <form
                    name="Marketing Contact"
                    method="POST"
                    target="_self"
                    data-netlify-honeypot="bot-field"
                    data-netlify="true"
                    onSubmit={handleSubmit}
                    class="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
                  >
                    <div>
                      <label
                        for="firstName"
                        class="block text-sm font-medium text-white"
                      >
                        First name
                      </label>
                      <div class="mt-1">
                        <input
                          type="text"
                          name="firstName"
                          id="firstName"
                          onChange={handleChange}
                          autocomplete="given-name"
                          class="block w-full rounded-md border-gray-300 py-3 px-4 text-gray-900 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                        />
                      </div>
                    </div>
                    <div>
                      <label
                        for="lastName"
                        class="block text-sm font-medium text-white"
                      >
                        Last name
                      </label>
                      <div class="mt-1">
                        <input
                          type="text"
                          name="lastName"
                          id="lastName"
                          onChange={handleChange}
                          autocomplete="family-name"
                          class="block w-full rounded-md border-gray-300 py-3 px-4 text-gray-900 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                        />
                      </div>
                    </div>
                    <div>
                      <label
                        for="email"
                        class="block text-sm font-medium text-white"
                      >
                        Email
                      </label>
                      <div class="mt-1">
                        <input
                          id="email"
                          name="email"
                          type="email"
                          onChange={handleChange}
                          autocomplete="email"
                          class="block w-full rounded-md border-gray-300 py-3 px-4 text-gray-900 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                        />
                      </div>
                    </div>
                    <div>
                      <div class="flex justify-between">
                        <label
                          for="phone"
                          class="block text-sm font-medium text-white"
                        >
                          Phone
                        </label>
                        <span id="phone-optional" class="text-sm text-gray-300">
                          Optional
                        </span>
                      </div>
                      <div class="mt-1">
                        <input
                          type="number"
                          name="phone"
                          id="phone"
                          autocomplete="tel"
                          onChange={handleChange}
                          class="block w-full rounded-md border-gray-300 py-3 px-4 text-gray-900 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                          aria-describedby="phone-optional"
                        />
                      </div>
                    </div>
                    <div class="sm:col-span-2">
                      <label
                        for="websiteLink"
                        class="block text-sm font-medium text-white"
                      >
                        Website
                      </label>
                      <div class="mt-1">
                        <input
                          type="text"
                          name="websiteLink"
                          id="websiteLink"
                          onChange={handleChange}
                          class="block w-full rounded-md border-gray-300 py-3 px-4 text-gray-900 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                        />
                      </div>
                    </div>
                    <div class="sm:col-span-2">
                      <label
                        for="subject"
                        class="block text-sm font-medium text-white"
                      >
                        How did you hear about us?
                      </label>
                      <div class="mt-1">
                        <select
                          type="text"
                          name="referral"
                          id="referral"
                          onChange={handleChange}
                          class="block w-full rounded-md border-gray-300 py-3 px-4 text-gray-900 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                        >
                          <option value="none">Please Select</option>
                          <option value="Referral">
                            Referral/Word-of-Mouth
                          </option>
                          <option value="Online Ad">Online Ad</option>
                          <option value="Streaming Ad">
                            Ad on Streaming Service
                          </option>
                          <option value="Social Media Ad">
                            Social Media Ad (Facebook, Instagram, etc.)
                          </option>
                          <option value="Ditial Ad Outside Of Home">
                            Digital Out of Home (bar, gym, gas station, etc.)
                          </option>
                          <option value="other">Other</option>
                        </select>
                      </div>
                    </div>
                    <div class="sm:col-span-2">
                      <div class="flex justify-between">
                        <label
                          for="message"
                          class="block text-sm font-medium text-white"
                        >
                          How can we help?
                        </label>
                        <span id="message-max" class="text-sm text-gray-300">
                          Max. 500 characters
                        </span>
                      </div>
                      <div class="mt-1">
                        <textarea
                          id="message"
                          name="message"
                          rows="4"
                          onChange={handleChange}
                          class="block w-full rounded-md border-gray-300 py-3 px-4 text-gray-900 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                          aria-describedby="message-max"
                        ></textarea>
                      </div>
                    </div>

                    <div class="sm:col-span-2 sm:flex sm:justify-end">
                      {SubmitDisabled === true ? (
                        <button
                          type="submit"
                          class="font-bold mt-2 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-white px-6 py-3 text-base font-medium text-[#00538a] shadow-sm hover:opacity-80 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                        >
                          Loading...
                        </button>
                      ) : (
                        <button
                          type="submit"
                          class="font-bold mt-2 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-white px-6 py-3 text-base font-medium text-[#00538a] shadow-sm hover:opacity-80 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                        >
                          Submit
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <Footer />
    </div>
  );
};
export default BetterMarketing;
